import React, { Component } from 'react';
import '../pages/Products.css';

class Products extends Component{
    render()
    {
        return  <div>
                    <div className="container-fluid bg-light mt-5 full-height">
                        <h1 className="pt-5 pl-3">Products page</h1>
                        <p className="pl-3">Products such IOT solution, machine interfacing</p>
                        <hr/>
                    </div>   
                </div>
    }
}

export default Products;
