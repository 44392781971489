import React, { Component } from 'react';
import {Link} from 'react-router-dom';
const { REACT_APP_VERSION } = process.env;
class Footer extends Component{
    render(){
        return <div>
                    {/* <div className="bg-light p-3 fixed-bottom">
                        <span className="text-white float-right"><a href="#">All Right Reserved By Aarohi Design Services</a></span>
                    </div> */}
                    {/* <footer className="pt-4 md-5 pt-md-5 bg-secondary text-white"> */}
                    <footer className="pt-4 bg-secondary text-white">
                        {/* <div className="row text-white px-auto">                            
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">

                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h5>Menu</h5>
                                <ul className="list-unstyled text-small">
                                <li><Link className="text-warning" to="/">Home</Link></li>
                                <li><Link className="text-warning" to="/Services">Services</Link></li>
                                <li><Link className="text-warning" to="/PortFolio">Portfolio</Link></li>
                                <li><Link className="text-warning" to="/PortFolio">Serving Industries</Link></li>
                                <li><Link className="text-warning" to="/AboutUs">About us</Link></li>
                                <li><Link className="text-warning" to="/Contact">Contact us</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h5>Resources</h5>
                                <ul className="list-unstyled text-small">
                                <li><a className="text-warning" href="#">Resource</a></li>
                                <li><a className="text-warning" href="#">Resource name</a></li>
                                <li><a className="text-warning" href="#">Another resource</a></li>
                                <li><a className="text-warning" href="#">Final resource</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h5>About</h5>
                                <ul className="list-unstyled text-small">
                                <li><a className="text-warning" href="#">Team</a></li>
                                <li><a className="text-warning" href="#">Locations</a></li>
                                <li><a className="text-warning" href="#">Privacy</a></li>
                                <li><a className="text-warning" href="#">Terms</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row text-white">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" >
                                <hr/>
                                <p>Copyright © 2020 Aarohi Design Services  (ADS WEB {REACT_APP_VERSION})</p>
                            </div>
                        </div> */}
                        <div className="row">
                            {/* <ul className="nav nav-list mx-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/"><p><i className="fa fa-fw fa-home"></i> Home</p></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/Services"><p><i className="fa fa-globe"></i> Services</p></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/PortFolio"><p><i className="fa fa-cogs"></i> Portfolio</p></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/PortFolio"><p><i className="fa fa-cogs"></i> Serving Industries</p></Link>
                                </li>                                
                                <li className="nav-item">
                                    <Link className="nav-link" to="/AboutUs"><p><i className="fa fa-book"></i> About us</p></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/Contact"><p><i className="fa fa-book"></i> Contact us</p></Link>
                                </li>
                            </ul> */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" >
                                <p>Copyright © 2020 Aarohi Design Services  (ADS WEB {REACT_APP_VERSION})</p>
                            </div>
                        </div>
                    </footer>
                </div>
    }
}

export default Footer;