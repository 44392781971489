import React, { Component } from 'react';
import '../pages/PortFolio.css';
import Flippy, { FrontSide, BackSide } from 'react-flippy/dist';

import image1 from '../images/caro1.jpeg';
import image3 from '../images/caro3.jpeg';
import productimage from '../assets/images/bgblack.jpg'
import pcbimage from '../assets/images/pcbimg.jpg';
import prototypeimage from '../assets/images/embeddeboard.jpg';
import softwareimage1 from '../assets/images/dbimage.jpg';
import codeview from '../assets/images/code_preview.jpg';
import appscree1 from '../assets/images/app_screen1.jpg';
import appscree2 from '../assets/images/app_photos/login2.jpg';
import appscree3 from '../assets/images/app_photos/menu2.jpg';

import Card_Portfolio from '../components/Card_Portfolio';

class PortFolio extends Component{
    render()
    {
        return  <div className="bg-light">
                    {/* <div className="parallax"><h1 className="text-center text-success">Portfolio Title and description</h1></div> */}
                    <div className="container-fluid mt-5">
                        {/* <h1 className="pt-5 pl-3">Portfolio page</h1>
                        <hr/> */}
                        <div className="row pt-5 py-2 m-0">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={prototypeimage} 
                                                overlay_text="Hardware Design from the Scratch"/>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={codeview} 
                                                overlay_text="Power Electronics Product Design Proto Type"/>                                
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={pcbimage} 
                                                overlay_text="PCB Design as per Enclosure Dimension"/>
                            </div>
                        </div>
                        <div class="row pt-2 py-2 m-0">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={image1} 
                                                overlay_text="PCB Design as per Enclosure Dimension"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={softwareimage1} 
                                                overlay_text="Database Structure Design For Scalability"/>
                            </div>                          
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={image3} 
                                                overlay_text="Website for Enterprise"/>
                            </div>
                        </div>
                        <div class="row py-2 m-0">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={image3} 
                                                overlay_text="Website for Enterprise"/>   
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={image3} 
                                                overlay_text="Custom Website Development"/>
                            </div>                            
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <Card_Portfolio bg_image={image3} 
                                                overlay_text="UI UX designer"/>
                            </div>
                        </div>
                        <div>
                        <h1 className="text-primary text-center pt-3">Custom IOT Application<hr/></h1>
                        <div class="row text-dark">
                            {/* <div className="col-md-3 col-lg-3 col-sm-3">
                                <div class="device-mockup nexus7 portrait white">
                                    <div class="device">
                                        <div class="screen">
                                            <img src={appscree1} class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-3 col-lg-3 col-sm-3"></div>
                            <div className="col-md-3 col-lg-3 col-sm-3">
                                <div class="device-mockup nexus7 portrait white ">
                                    <div class="device">
                                        <div class="screen">
                                            <img src={appscree2} class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-md-3 col-lg-3 col-sm-3">
                                <div class="device-mockup nexus7 portrait white">
                                    <div class="device">
                                        <div class="screen">
                                            <img src={appscree3} class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3 col-lg-3 col-sm-3">
                                <div class="device-mockup nexus7 portrait white">
                                    <div class="device">
                                        <div class="screen">
                                            <img src={appscree1} class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
                                <h2 class="featurette-heading">Oh yeah, it’s that good. <span class="text-muted">See for yourself.</span></h2>
                                <p class="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                            </div> */}
                        </div>
                        </div>
                        {/* <div class="row py-2 m-0">
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <Card_Portfolio bg_image={image1} 
                                                overlay_text="As per user "/>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <Card_Portfolio bg_image={softwareimage1} 
                                                overlay_text="----------------------"/>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <Card_Portfolio bg_image={image1} 
                                                overlay_text="----------------------"/>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <Flippy
                                    flipOnHover={true} // default false
                                    flipOnClick={false} // default false
                                    flipDirection="horizontal" // horizontal or vertical
                                    ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                                    style={{ width: '100%', height: '100%' }} /// these are optional style, it is not necessary
                                >
                                    <FrontSide
                                    style={{
                                        backgroundColor: '#41669d',
                                    }}
                                    >
                                    RICK
                                    </FrontSide>
                                    <BackSide
                                    style={{ backgroundColor: '#175852'}}>
                                    ROCKS
                                    </BackSide>
                                </Flippy>
                            </div>
                        </div> */}                        
                    </div>
                </div>
    }
}

export default PortFolio;