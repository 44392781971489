import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
class Services extends Component{
    render()
    {
        return  <div>
                    <div className="container-fluid bg-light mt-5">
                        <h1 className="pt-5 pl-3">Our Services</h1>
                        {/* <div class="btn-group btn-breadcrumb">
                            <a href="#" class="btn btn-light"><i class="fa fa-home fa-1x fa-icon-image"></i>HOME</a>
                            <Link className="btn btn-light" to="/Services"><h5>Services</h5></Link>
                        </div> */}
                        <hr/>
                        <div class="row pt-5 m-0">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="card text-center pt-5 text-primary shadow-lg">
                                    <i class="fa fa-microchip fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">High Power Design Expertise</h5>
                                        <p class="card-text text-justify">
                                        High Voltage, High Current measurement and control Hardware design, Optimum 
                                        high-power driver design selection based on the application requirement in short we are deploying power efficient applications
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="card text-center pt-5 text-success shadow-lg">
                                    <i class="fa fa-microchip fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Hardware Proto Type Designing</h5>
                                        <p class="card-text text-justify">
                                            This is an amazing set of animated accordions based completely on CSS. 
                                            They come oriented both vertically and horizontally in order to fit properly in your project. 
                                            In order to see the slides,
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="card text-center pt-5 text-primary shadow-lg">
                                    <i class="fa fa-microchip fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Motor Control Design Expertise</h5>
                                        <p class="card-text text-justify">
                                            Up to 7.5 H.P. Solar BLDC/PMSM/Induction motor controller design
                                            20V Battery operated BLDC motor controller for 24000 RPM, up to 150 Amp current, for 
                                            power tool application
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="card text-center pt-5 text-info shadow-lg">
                                    <i class="fa fa-eraser fa-5x fa-icon-image" ></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Low Power Design Expertise</h5>
                                        <p class="card-text text-justify">
                                            Low Power Chip selection, Dynamic voltage and frequency scaling,
                                            Dynamic power switching,Power consumption profiling with Dynamic power management 
                                        </p>
                                    </div>
                                </div>
                            </div>
                                                  
                        </div>
                        <div class="row py-5 m-0">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="card text-center pt-5 text-warning shadow-lg">
                                    <i class="fa fa-keyboard-o fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Embedded Firmware & Software Development</h5>
                                        <p class="card-text text-justify">
                                        Custom Applications and Graphics UI Programming  
                                        Design,Development and Testing as per Usability with coding standards
                                        </p>
                                    </div>
                                </div>
                            </div>      
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div class="card text-center pt-5 text-info shadow-lg">
                                    <i class="fa fa-laptop fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Custom Destkop Application Software Solution </h5>
                                        <p class="card-text text-justify">
                                        .NET Custom Application With Machine Interfacing (Scada logic) with stunning GUI and productive functionality with scalable structure for future needs 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div class="card text-center pt-5 text-danger shadow-lg">
                                    <i class="fa fa-mobile-phone fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Mobile Apps</h5>
                                        <p class="card-text text-justify">
                                            Tailored App for IOT, Enterprise solution and Tracking app in preferred technology such as Java or ReactNative with proper Coding struture for future maintainance or Expansion
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div class="card text-center pt-5 text-primary shadow-lg">
                                    <i class="fa fa-desktop fa-5x fa-icon-image"></i>
                                    <div class="card-body">
                                        <h5 class="card-title">Web Application</h5>
                                        <p class="card-text text-justify">
                                            IOT Solution(With Database Design) as Industries 4.0 standard, Enterprise Application (With Database Design),Small to Big Enterprise Custom Website as well Web App         
                                        </p>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
    }
}

export default Services;