import React, { Component } from 'react';
import '../pages/AboutUs.css';

class AboutUs extends Component{
    render()
    {
        return <div>
                    <div className="container-fluid bg-light mt-5 full-height">
                        <h1 className="pt-5 pl-3">About us page</h1>
                        <p className="pl-3">Company profile,strength and capabilities</p>
                        <hr/>
                    </div>    
                </div>
    }
}

export default AboutUs;
