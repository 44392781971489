import React from 'react';
import ReactDOM from 'react-dom';
import {Route,HashRouter} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';
import TopHeader from './pages/TopHeader';
import dashboard from './pages/DashBoard';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
import Services from './pages/Services';
import PortFolio from './pages/PortFolio';
import Products from './pages/Products';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
// import LogIn from './pages/LogIn';

const pagedata= <HashRouter>
                    <TopHeader/>
                      <body className="bg-light">
                      <Route exact path="/" component={dashboard} />
                      <Route path="/Services" component={Services} />
                      <Route path="/PortFolio" component={PortFolio} />
                      <Route path="/Products" component={Products} />
                      <Route path="/AboutUs" component={AboutUs} />
                      <Route path="/Contact" component={Contact} />
                      {/* <Route path="/log-in" component={LogIn} /> */}
                      </body>
                    <Footer/>                      
                </HashRouter>

ReactDOM.render(pagedata,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
