import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../pages/TopHeader.css';

class TopHeader extends Component{
    render()
    {
        return  <nav className="navbar navbar-expand-md bg-dark fixed-top ">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand" href="#">
                        <h4 className="text-light"><i className="fa fa-globe"></i> ADS</h4>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/"><p><i className="fa fa-fw fa-home"></i> Home</p></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Services"><p><i className="fa fa-globe"></i> Services</p></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/PortFolio"><p><i className="fa fa-cogs"></i> Portfolio</p></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/PortFolio"><p><i className="fa fa-cogs"></i> Serving Industries</p></Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/Products"><p><i className="fa fa-group"></i> Products</p></Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/AboutUs"><p><i className="fa fa-book"></i> About us</p></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Contact"><p><i className="fa fa-book"></i> Contact us</p></Link>
                            </li>

                            {/* <li className="nav-item">
                                <Link className="nav-link text-danger" to="/log-in"><h5> <i className='fa fa-power-off'></i> Logout</h5></Link>
                            </li> */}
                        </ul>
                    </div>
                </nav>
    }
}

export default TopHeader;