import React, { Component } from 'react';
import '../components/Card_Portfolio.css';

class Card_Portfolio extends Component{
    render(){
        return <div>
                    <div className="hovereffect">
                        <img className="img-fluid" src={this.props.bg_image} alt="" />
                        <div className="overlay">
                            <h2>{this.props.overlay_text}</h2>
                        </div>
                    </div>
                </div>
    }
}

export default Card_Portfolio;
