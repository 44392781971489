import React, { Component } from 'react';
import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/img2.jpg';
import img3 from '../assets/images/img3.jpg';
import codeview from '../assets/images/code_preview.jpg';
import '../pages/DashBoard.css';
import '../assets/device-mockups/device-mockups.min.css';
import TitleCarou from '../components/TitleCarou';
import bgblack from '../assets/images/bgblack_title_texts.jpg';
import oscillo from '../assets/images/cro.jpg';
import process1 from '../assets/images/dashboard/process1.jpg';

class Dashboard extends Component{
    render()
    {
        return  <div>
                    <div className="container-fluid bg-light">
                        <div className="row pt-5">
                            <img className="img-fluid w-100 full-height" src={bgblack}/>
                            {/* <div className="text-center text-white caption">
                                <h3>Looking for Product Development Services? </h3>
                                <br/>
                                <h2 className="cd-headline clip">
                                    <b className="is-visible text-white"> Need Complete solution for Your <span className="text-warning"> Dream product</span></b>
                                </h2>
                                <a href="#0" data-scroll-nav="1" className="butn butn-bord">
                                    <span>See Portfolio</span>
                                </a>
                                <a href="#" target="_blank" className="butn butn-bg">
                                    <span>Our Expertise</span>
                                </a>
                            </div> */}
                        </div>

                        {/* <div className="row pt-5 text-center" >
                            <div className="col-md-4 col-lg-4 col-sm-4 pt-5 text-center">
                                <h1>Input waveforms</h1>
                                <h1>Input waveforms</h1>
                                <h1>Input waveforms</h1>
                                <h1>Input waveforms</h1>
                                <h1>Input waveforms</h1>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 pt-5">
                                <div class="device-mockup ipad_air landscape white">
                                    <div class="device">
                                        <div class="screen">
                                            <img src={oscillo} class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <TitleCarou></TitleCarou> */}
                        {/* section: 3 cards */}
                        <div className="row pb-5 mt-4 py-4">
                            <div className="col-lg-4">
                                <div className="card p-4 shadow-lg" >
                                    <div className="card-title text-center text-primary"><h5>Project Definition</h5></div>
                                    <img className="card-img-top" src={process1} alt="Card image cap"/>
                                    <div className="card-body">
                                        <h5 className="card-text text-justify">Proper Requirement Understading of Project in terms of quality,cost and time is <span className="text-success">Most important aspect </span>of any project</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card p-4 shadow-lg" >
                                    <div className="card-title text-center text-primary"><h5>Hassel Free Project Execution</h5></div>
                                    <img className="card-img-top" src={img2} alt="Card image"/>
                                    <div className="card-body">
                                        <h5 className="card-text">Due to improper defination,planning,process,design or maintainance majority ideas failed as <span className="text-success"> Reality as expectation</span> of any organization or individual </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card p-4 shadow-lg">
                                    <div className="card-title text-center text-primary"><h5>Technical Importance</h5></div>
                                    <img className="card-img-top" src={img3} alt="Card image cap"/>
                                    <div className="card-body">
                                        <h5 className="card-text">Customer Satisfaction is the <span className="text-success">untold ethics</span> of our firm which means no compromise on project expectation or user experience</h5>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="container-fluid">
                        {/* multiple section  */}
                        {/* <hr/> */}

                        <div class="row featurette bg-primary">
                            <div className="col-md-2"></div>
                            <div class="col-md-4">
                                <h2 className="featurette-heading text-center">Why Us?</h2>
                                <hr/>
                                <h4 className>Over 16 years+ Solid Track Record In the field of Embedded Design & Development</h4>
                                <h4>From concept to product We are delivering Innovative solutions</h4>
                                <h4>We bring customer delight by providing end to end solutions</h4>
                                {/* <p class="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p> */}
                            </div>
                            <div class="col-md-4">
                                {/* <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 500x500"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg> */}
                                <img src={img1} className="img-fluid"/>
                            </div>
                        </div>

                        {/* <hr/> */}

                        {/* <div class="row featurette text-dark">
                            <div className="col-md-2 col-lg-2 col-sm-2"></div>
                            <div class="col-md-4  col-lg-4 col-sm-4 order-md-2">
                                <h2 class="featurette-heading">Oh yeah, it’s that good. <span class="text-muted">See for yourself.</span></h2>
                                <p class="lead">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 order-md-1">
                            <img src={img1} className="img-fluid"/>
                            </div>
                        </div>                     */}

                        {/* <hr/> */}
                        
                        {/* <div class="row featurette bg-dark">
                            <div className="col-md-2"></div>
                            <div class="col-md-4">
                                <h2 class="featurette-heading pt-5">And lastly, this one. <span class="text-muted">Checkmate.</span></h2>
                                <p class="lead pt-5">Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.</p>
                            </div>
                            <div class="col-md-4">
                            <img src={img1} className="img-fluid"/>
                            </div>
                        </div> */}

                        {/* <hr class="featurette-divider"/> */}
                    </div>             
                    
                </div>
    }
}

export default Dashboard;