import React, { Component } from 'react';
import '../pages/Contact.css';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// CommonJS
// const Swal = require('sweetalert2')

class Contact extends Component{

    constructor(){
        super();
     }

     
    DisplayMsg=()=>{
        // alert("fds")
        // Swal.fire({
        //     title: 'Let\'s do it !',
        //     text: 'Hey There nice to hear from you',
        //     icon: 'success',
        //     confirmButtonText: 'Cool'
        //   })

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'info',
            title: 'successfully completed see you soon!!!'
          })
    }
    
    render(){
        return  <div>
                    <div className="container-fluid mt-5 ">
                        {/* <h1 className="pt-5 pl-3">Contact page</h1>
                        <p className="pl-3">Contact Details of customer will uploaded to site</p>     
                        <hr/> */}
                        
                        <div class="row bg-image ">
                            <div className="col-lg-3">

                            </div>
                            {/* <form className=""> */}
                                <div className="col-lg-6 pt-5">
                                    <div class="card">
                                        <div class="card-header">
                                            <h1>Let's Talk</h1>
                                        </div>
                                        <div class="card-body">
                                            <form>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1" class="font-weight-bold">Full name</label>
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Full name"/>
                                                    <small id="emailHelp" class="form-text text-muted"></small>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1" class="font-weight-bold">Email address
                                                    </label>
                                                    <input type="password" class="form-control" id="exampleInputPassword1"
                                                        placeholder="Enter email address"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleTextarea" class="font-weight-bold">Message</label>
                                                    <textarea class="form-control" id="exampleTextarea" rows="3" placeholder="Enter your message"></textarea>
                                                </div>
                                                
                                                <button  onClick={this.DisplayMsg}  type="submit" class="btn btn-primary font-weight-bold">Send Query</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            {/* </form> */}
                        </div>                    
                    </div>         
                </div>
    }
}

export default Contact;
