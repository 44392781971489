import React, { Component } from 'react';
import '../components/TitleCarou.css';
import bgblack from '../assets/images/bgblack.jpg';

class TitleCarou extends Component {
    state = {  }
    render() {
        return (
            <div>
                <div className="header valign bg-img creative m-0 p-0" data-background={bgblack}>
                    {/* <div className="svg">
                        <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                            <polygon points="-30,300 355.167,210.5 1432.5,290 1920,198.5 1920,300"></polygon>
                        </svg>
                    </div> */}
                    <div className="container">
                        <div className="row">
                            <div className="full-width text-center text-white caption">
                                <h3>Looking for Product Development Services? </h3>
                                <br/>
                                <h2 className="cd-headline clip">
                                    <b className="is-visible text-white"> Need Complete solution for Your <span className="text-warning"> Dream product</span></b>
                                </h2>
                                <a href="#0" data-scroll-nav="1" className="butn butn-bord">
                                    <span>See Portfolio</span>
                                </a>
                                <a href="#" target="_blank" className="butn butn-bg">
                                    <span>Our Expertise</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TitleCarou;